import React, { useState } from "react";
import UploadIcon from "assets/uploadIcon.svg";
import { fileExtension } from "utils/fileextension";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import InputHeader from "./InputHeader";
import { wordsLength } from "utils/getWordsLength";
import useAutoFocus from "hooks/useAutoFocus";
import { trySampleText } from "utils/sampletext-generator";
const InputBox = ({ sourceText = "", setSourceText, lang, isDisabled,textLoader, setTextLoader, setTranslationText}) => {
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);

  const [file, setFile] = useState(false);
  const wordCount = 0;
  useAutoFocus("textarea");

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);
    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);
      setSourceText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };


  return (
    <div
      className={`relative w-full
       p-3 bg-white rounded-lg shadow-box border-none mt-4 lg:mt-0`}
    >
      <InputHeader
        title="Source text"
        text={sourceText}
        setText={setSourceText}
      />
      {fileUploadLoading ? (
        <FileLoadingInput file={file} />
      ) : sizeLimitError && !fileUploadLoading ? (
        <ReUploadFile
          file={file}
          setFile={setFile}
          setSizeLImitError={setSizeLImitError}
          uploadFile={uploadFile}
        />
      ) : uploadError && !fileUploadLoading ? (
        <UnsupportedFile
          file={file}
          setSizeLImitError={setSizeLImitError}
          setFile={setFile}
          uploadFile={uploadFile}
          setFileUploadError={setUploadError}
        />
      ) : (
        <div className="relative">
          <textarea
            autoFocus
            id="input-textarea"
            dir={
              lang === "fa" || lang === "he" || lang === "ar" ? "rtl" : "ltr"
            }
            className={` h-[250px] anonymize_tool font-primary  placeholder-[#B4B4B4] text-black leading-[26px] py-3 bg-transparent text-base overscroll-y-none resize-none border-none outline-none block w-full`}
            // placeholder="Enter your source text here"
            placeholder={textLoader ? "" : "Type your text here or"}
            value={sourceText}
            dangerouslySetInnerHTML={{
              __html: sourceText.replace(/\n/g, "<br />"),
            }}
            onChange={(e) => setSourceText(e.target.value)}
          />
          {textLoader && (
            <div className="absolute top-[13px] left-[14px] text-[#B4B4B4] italic">
              Adding sample text...
            </div>
          )}
          {!sourceText  && !textLoader && (
            <button
              className="absolute top-[13px] left-[176px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
              onClick={() => trySampleText(setSourceText, setTextLoader, "tryToolTextTranslationTool",setTranslationText)}
            >
              Try Sample Text
            </button>
          )}
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        {sourceText ? (
          <p className="text-right mt-2 text-sm text-[#9C9C9C]">
            {
              sourceText
                .trim()
                .split(/\s+/)
                .filter((word) => word.length > 0).length
            }{" "}
            words
          </p>
        ) : (
          <>
            <label
              htmlFor={`uploadFile`}
              className="flex gap-2 cursor-pointer "
            >
              <img src={UploadIcon} alt="upload_icon" />
              <span className="text-[#858585] font-opensans text-sm font-semibold">
                Upload File
              </span>
            </label>
            <input
              type="file"
              className="hidden"
              id={`uploadFile`}
              onChange={uploadFile}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InputBox;
