import React, { useState, useEffect } from "react";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import ToolsBanner from "components/commontools/toolsBanner";
import InputBox from "components/PostTranslation/Input";
import TranslationBox from "components/PostTranslation/TranslationBox";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { navigate } from "gatsby";
import DetailAnalysis from "components/QualityTools/DetailAnalysis";
import useDebounce from "components/QualityTools/useDebounce";
import axios from "axios";
import { Languages } from "components/QualityTools/LanguagesData";
import detectLanguage from "utils/detectLanguage";
import BannerSection from "components/PostTranslation/BannerSection";
import BannerImage from "assets/post_translation_banner.png";


const apiEndpoints = [
  "translationQuality",
  "glossaryGenerator",
  "consistencyChecker",
  "untranslated_term_detection",
  "missing_term_detection",
  "source_vs_translation_qa",
  "language_detector",
];

const Index = () => {
  const [sourceText, setSourceText] = useState("");
  const [translationText, setTranslationText] = useState("");
  const [shareId, setShareId] = useState(null);
  const [apiCall, setApiCall] = useState(0);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const debouncedInputText = useDebounce(sourceText, 3000);
  const debouncedOutputText = useDebounce(translationText, 3000);
  const [outputLang, setOutputLang] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [textLoader, setTextLoader] = useState(false);
  const responses = [];

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);

  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectLanguageForTarget(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectLanguageForSource = async (text) => {
    const { languageCode, languageName } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
      setTranslateFromInput(languageName);
    }
  };

  const detectLanguageForTarget = async (text) => {
    const { languageCode, languageName } = await detectLanguage(text);
    if (languageCode) {
      setOutputLang(languageCode);
      setTranslateToInput(languageName);
    }
  };

  const generateShareId = async () => {
    const { share_id } = await http().get("/api/v2/generate-share-id");
    setShareId(share_id);
  };
  useEffect(() => {
    generateShareId();
  }, []);

  const getOutputs = async () => {
    setApiCall((preVal) => preVal + 1);
    try {
      await Promise.all(
        apiEndpoints.map(async (tool_name) => {
          try {
            const isLanguageDetector = tool_name === "language_detector";
            const res = await http().post(endpoints.forms.aiTools, {
              tool_name,
              source_text: sourceText,
              translation: translationText,
              share_id: shareId,
              source_language_code: inputLang,
              target_language_code: isLanguageDetector ? undefined : outputLang,
              // user_text: isLanguageDetector ? sourceText : translationText,
              user_text: translationText,
              collective_tool_name:"post_translation"
            });
            responses.push(res);
          } catch (error) {
            console.error(`Failed API call for tool_name: ${tool_name}`, error);
            setError(error);
          } finally {
            setError(false);
          }
        })
      );
    } catch (error) {
      console.error("Error during API calls:", error);
    } finally {
      setLoader(false);
    }
  };
  const generateReport = async () => {
    setLoader(true);
    setError("");
    if (apiCall < 3 && responses?.length < 6) {
      await getOutputs();
    }
    if (shareId === undefined && !responses.success) {
      setError("Unable to generate a response. Please try again.");
      return;
    }
    navigate(`/tools/post-translation/result?share-id=${shareId}`);
  };
  const isDisabled =
    !sourceText || !translationText || !inputLang || !outputLang;

    const BannerData = [
      {
        text: "<span class='font-bold'>Detect language inconsistencies</span> and verify if the translation matches the intended language.",
      },
      {
        text: "<span class='font-bold'>Identify missing or untranslated text</span> to ensure completeness.",
      },
      {
        text: "<span class='font-bold'>Check for terminology consistency</span> and detect inconsistent translations of repeated words or phrases.",
      },
      {
        text: "<span class='font-bold'>Assess translation quality</span> using AI-driven Translation Quality Scores that analyze <span class='font-bold'>fluency, accuracy, grammar, style, and terminology.</span>",
      },
      {
        text: "<span class='font-bold'>Generate bilingual glossaries</span> automatically to maintain consistent terminology across projects.",
      },
      {
        text: "<span class='font-bold'>Improve translation coherence</span> with a side-by-side comparison of the source and target text.",
      },
      {
        text: "<span class='font-bold'>Enhance efficiency and reduce costs</span> by helping linguists <span class='font-bold'>detect and fix issues early</span>, preventing time-consuming corrections.",
      },
    ];
  return (
    <Layout>
      <SEO
        title="AI-Powered Translation Quality Assessment Tool"
        description="The Tomedes AI-Powered Translation QA Tool checks translation accuracy by comparing it to the source text. Get a detailed report and translation quality scores for fluency, grammar, terminology, and style."
        slug="/tools/pre-translation"
      />
      <div className="bg-[#F5F5F5]">
        <div className="pt-7">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Source-to-Translation ai quality assessment"
            
          />
          <div className="grid md:grid-cols-2 mt-10 gap-4">
            <InputBox
              sourceText={sourceText}
              textLoader={textLoader}
              setTextLoader={setTextLoader}
              setSourceText={setSourceText}
              setTranslationText={setTranslationText}
              lang={inputLang}
              isDisabled={isDisabled}
            />
            <TranslationBox
              translationText={translationText}
              setTranslationText={setTranslationText}
              generateReport={generateReport}
              loader={loader}
              lang={outputLang}
              isDisabled={isDisabled}
              textLoader={textLoader}
              setTextLoader={setTextLoader}
            />
          </div>
        </div>
      </div>
      <BannerSection  
      title="Compare the Translation Against the Source for a Reliable QA Check"
      description = 'The AI-Powered Translation Auditor provides a comprehensive framework for measuring translation quality, helping linguists, businesses, and localization professionals produce translations that meet the highest standards of quality. <br /><br />By comparing your translation directly against the original source text, this tool detects errors, improves consistency, and enhances overall accuracy, helping you avoid complaints and costly revisions.'
        BannerData={BannerData}
        BannerImage={BannerImage}
        text=" Whether you need to evaluate machine translation output, verify human translations, or maintain high translation standards at scale, this tool provides real-time, AI-powered insights to improve quality, reduce errors, and ensure translation accuracy before delivery."
      />
    </Layout>
  );
};

export default Index;
