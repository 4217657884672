import React from "react";

const InputHeader = ({ title = "Source text", text, setText }) => {
  return (
    <div className="border-b border-[#B0B0B0] pb-2 flex justify-between items-center">
      <span className="text-[#6E6E6E] text-base font-bold font-opensans">
        {title}
      </span>{" "}
      {text && (
        <svg
          onClick={() => setText("")}
          class="cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
            fill="#69AAE8"
          ></path>
        </svg>
      )}
    </div>
  );
};

export default InputHeader;
